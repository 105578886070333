<template>
  <v-snackbar
    bottom
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :multi-line="snackbar.multiline === true"
    :color="snackbar.color"
    >{{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="CLOSE_ALERT"> Cerrar </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  computed: {
    snackbar() {
      return this.$store.state.snackbar
    },
  },
  methods: {
    ...mapMutations(['CLOSE_ALERT']),
  },
}
</script>
